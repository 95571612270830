import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.parametros.dialogError),callback:function ($$v) {_vm.$set(_vm.parametros, "dialogError", $$v)},expression:"parametros.dialogError"}},[_c(VCard,[_c(VCardText,[_c(VCard,{staticClass:"elevation-0 text-center pt-4"},[_c('span',{staticClass:"text-headline",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.parametros.mensaje)+" ")])])],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":_vm.parametros.color,"dark":"","block":""},on:{"click":function($event){_vm.parametros.dialogError = false}}},[_vm._v("Cerrar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }