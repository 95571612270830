import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(VueResource)

Vue.http.interceptors.push((request, next) => {
  request.headers.set('Accept', 'application/json')
  request.headers.set('Access-Control-Allow-Origin', '*')
  next()
});

Vue.http.options.root = 'https://web-back-inbi.club/kpi/';


new Vue({
  router,
  store,
  vuetify,
  render: function(h) { return h(App) }
}).$mount('#app')