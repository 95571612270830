<template>
  <v-app>
    <v-main>
      <v-container class="fill-height">
        <v-row align="center" justify="center" class="align-end" align-content="center">
          <v-col cols="12" md="6" lg="4" xl="3" align="center" justify="center">
            <v-card class="elevation-10 shadowCard">
              <v-card-title primary-title>
                <v-spacer></v-spacer>
                Generador de codigos
                <v-spacer></v-spacer>
              </v-card-title>
              <v-divider class="ma-4"></v-divider>
              <v-card-text class="pb-0" v-if="!codigo">
                <v-text-field
                  dense
                  prepend-inner-icon="mdi-account"
                  label="Usuario"
                  v-model="usuario"
                  clearable
                  filled
                  rounded
                  dense
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  dense
                  prepend-inner-icon="mdi-lock"
                  label="Contraseña"
                  v-model="password"
                  clearable
                  filled
                  rounded
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  type="password"
                ></v-text-field>
              </v-card-text>

              <v-card-text class="pb-0" v-else>
                <div class="ma-auto position-relative" style="max-width: 300px">
                  <v-otp-input
                    v-model="codigo"
                    readonly
                  ></v-otp-input>
                </div>
              </v-card-text>

              <v-card-actions class="px-4 pb-6" v-if="!codigo">
                <v-btn rounded block class="btn-login" @click="validate()" dark>Iniciar sesión</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-app>
</template>

<script>

  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  // Componentes
  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    props:[
      'inscribir'
    ],

    data: () => ({
      // Datos del alumno inscrito
      alumnoNuevo: false,
      alumnos:[],
      id_alumno: null,

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,
      usuario:'',
      password:'',
      codigo:null
    }),

    watch: {
    },

    async created(){
    },

    methods:{
      validate( ){
        const payload = {
          usuario  : this.usuario,
          password : this.password,
        }

        this.cargar = true
        this.$http.post('asistencia.teacher', payload).then(response=>{
          this.codigo   = response.body.codigo
          this.usuario  = null
          this.password = null
          this.validarSuccess('Código generado correctamente')
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      }
    },

    computed:{
    },
  };
</script>
<style>

  main{
    background: #F4F2F2 ;
  }

  .v-application {
    /*font-family: sans-serif !important;*/
    /*font-family: "Helvetica Neue",sans-serif !important;*/
  }

 
  .v-navigation-drawer__content{

  }

  .v-navigation-drawer__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-navigation-drawer__content::-webkit-scrollbar:vertical {
    width: 8px !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar:vertical{
    width: 8px !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar-button:increment,.v-navigation-drawer__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-navigation-drawer__content::-webkit-scrollbar:horizontal{
    height: 8px !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  html{
    background: #F4F2F2;
  }

  .v-main__wrap{
    background: #F4F2F2 !important;
  }

  .v-application--is-ltr .v-list-item__icon:first-child{
    margin-right: 8px !important;
  }

  .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 6px !important;
  }


  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    align-self: center;
    margin: 0;
    min-width: 0px !important;
    justify-content: flex-end;
  }

  .shadowCard{
    border-radius: 8px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.2)  !important;
  }

  .btn-login{
    background: rgb(0,26,252);
    background: linear-gradient(90deg, rgba(0,26,252,1) 0%, rgba(0,255,252,1) 100%);
  }


</style>
