import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticClass:"align-end",attrs:{"align":"center","justify":"center","align-content":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","xl":"3","align":"center","justify":"center"}},[_c(VCard,{staticClass:"elevation-10 shadowCard"},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c(VSpacer),_vm._v(" Generador de codigos "),_c(VSpacer)],1),_c(VDivider,{staticClass:"ma-4"}),(!_vm.codigo)?_c(VCardText,{staticClass:"pb-0"},[_c(VTextField,{attrs:{"dense":"","prepend-inner-icon":"mdi-account","label":"Usuario","clearable":"","filled":"","rounded":"","dense":"","rules":[v => !!v || 'Item is required']},model:{value:(_vm.usuario),callback:function ($$v) {_vm.usuario=$$v},expression:"usuario"}}),_c(VTextField,{attrs:{"dense":"","prepend-inner-icon":"mdi-lock","label":"Contraseña","clearable":"","filled":"","rounded":"","dense":"","rules":[v => !!v || 'Item is required'],"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_c(VCardText,{staticClass:"pb-0"},[_c('div',{staticClass:"ma-auto position-relative",staticStyle:{"max-width":"300px"}},[_c(VOtpInput,{attrs:{"readonly":""},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=$$v},expression:"codigo"}})],1)]),(!_vm.codigo)?_c(VCardActions,{staticClass:"px-4 pb-6"},[_c(VBtn,{staticClass:"btn-login",attrs:{"rounded":"","block":"","dark":""},on:{"click":function($event){return _vm.validate()}}},[_vm._v("Iniciar sesión")])],1):_vm._e()],1)],1)],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }